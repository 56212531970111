import React, {useState} from "react";
import {withStyles} from "@mui/styles";
import styles from "../Comments/Comments-jss";
import {connect} from "react-redux";
import {LiveEvent} from "../Events/EventsDTO";
import classNames from "classnames";
import {Grid, IconButton, TextField, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {Check, FileCopyOutlined} from "@mui/icons-material";
import { Profile } from "features/User/userDTO";

type Properties = {
    classes: any,
    profile: Profile,
    liveEvent: LiveEvent,
}

function WidgetsMenu(props: Properties) {
    const {
        classes,
        profile,
    } = props;

    const [copyIcon, setCopyIcon] = useState<{[key: string]: any}>({
            url: <FileCopyOutlined />,
            key: <FileCopyOutlined />
        }
    );

    const {t} = useTranslation();

    const copyToClipBoard = (text: string, kind: string) => {
        window.navigator.clipboard.writeText(text);
        setCopyIcon({...copyIcon, [kind]: <Check />});
        setTimeout(() => setCopyIcon({...copyIcon, [kind]: <FileCopyOutlined />}), 500);
    }

    const getIcon = (kind: string) => {
        return copyIcon[kind];
    }

    return (
        <Grid container direction="row" className={classNames(classes.root)} spacing={5}>
            <Grid item>
                <Typography gutterBottom variant="h5">
                    {t('Widgets & Overlays')}
                </Typography>
            </Grid>
            <Grid item container direction="row" alignItems="start">
                <Grid item xs={12}>
                    <Typography gutterBottom variant="h6">
                        {t('Live Stream Comments')}
                    </Typography>
                </Grid>

                <Grid item xs={10}>
                    <TextField
                        label={t("Comments Widget URL")}
                        fullWidth
                        variant="filled"
                        helperText={t("Use this URL to monitor the comments or to add them to your OBS Studio")}
                        value={`${window.location.origin}#/widget/comments/user/${profile.code}`}
                        onFocus={(event) => event.target.select()}
                        InputProps={{
                            endAdornment: (
                                <IconButton edge="end" color="default" onClick={ () => copyToClipBoard(`${window.location.origin}#/widget/comments/user/${profile.code}`, 'url') }>
                                    {getIcon('url')}
                                </IconButton>
                            ),
                        }}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
}

const mapStateToProps = function (state: any) {
    return {
        profile: state.user.profile,
    };
};

export default withStyles(styles)(connect(mapStateToProps)(WidgetsMenu));
