import React, {useEffect} from 'react'
import {enUS, ptBR} from "@mui/material/locale";
import {connect} from "react-redux";
import {createTheme, ThemeProvider} from "@mui/material";
import usePageViews from "../../packages/useGTM/usePageViews";
import {useLocation, useNavigate} from "react-router-dom";

const theme = createTheme({
    palette: {
        type: 'light',
        primary: {
            // main: '#ff0022',
            main: '#feb83f',
        },
        secondary: {
            main: '#dcf1fe',
        },
        background: {
            default: '#ffffff',
        },
        // error: {
        // },
        text: {
            primary: '#000000',
        },
    },
    overrides: {
        MuiInputLabel: {
            root: {
                "&$focused": {
                    color: '#1f1f1f',
                }
            }
        },
    }
}, ptBR);

function Layout(props) {
    const {
        children,
        isLoggedIn,
        profile,
    } = props;

    const navigate = useNavigate();

    const location = useLocation();

    usePageViews(profile?.code);

    useEffect(() => {
        console.debug('---------------', location.pathname, '---------------');
        if (!isLoggedIn &&
            ['/', '/login', '/signup', '/widget/comments/user', '/login-magic-link', '/close'].includes(location.pathname) === false &&
            !location.pathname.startsWith('/widget/comments/user')) {
            navigate('/');
        }
    }, [isLoggedIn]);

    return (
        <ThemeProvider theme={theme}>
            {children}
        </ThemeProvider>
    );
}

const mapStateToProps = function (state) {
    return {
        isLoggedIn: state.auth.isLoggedIn,
        profile: state.user.profile,
    };
};
export default connect(mapStateToProps)(Layout)
