import { Dispatch } from 'redux';
import publicCommentsService from './publicCommentsService';
import { CommentsResponse } from './publicCommentsTypes';

export const types = {
    FETCH_PUBLIC_COMMENTS_REQUEST: 'FETCH_PUBLIC_COMMENTS_REQUEST',
    FETCH_PUBLIC_COMMENTS_SUCCESS: 'FETCH_PUBLIC_COMMENTS_SUCCESS',
    FETCH_PUBLIC_COMMENTS_FAILURE: 'FETCH_PUBLIC_COMMENTS_FAILURE',

    RESET_PUBLIC_COMMENTS: 'RESET_PUBLIC_COMMENTS',
    UNSUBSCRIBE_PUBLIC_COMMENTS: 'UNSUBSCRIBE_PUBLIC_COMMENTS',
};

// Create a single AbortController instance that we can reference
let abortController: AbortController | null = null;

export const subscribeToPublicComments = (userUuid: string) => {
    return async (dispatch: Dispatch) => {
        // Create new AbortController for this subscription
        abortController = new AbortController();

        dispatch({ type: types.FETCH_PUBLIC_COMMENTS_REQUEST });

        let lastCommentTimestamp = new Date().getTime() / 1000;

        while (!abortController.signal.aborted) {
            console.debug('[subscribeToPublicComments] Fetching comments', lastCommentTimestamp);
            try {
                const commentsResponse: CommentsResponse = await publicCommentsService.fetchPublicComments(userUuid, lastCommentTimestamp);
                if (abortController.signal.aborted) break;

                if (commentsResponse.count > 0) {
                    dispatch({
                        type: types.FETCH_PUBLIC_COMMENTS_SUCCESS,
                        comments: commentsResponse.comments,
                        lastCommentTimestamp: commentsResponse.lastCommentTimestamp,
                    });
                    lastCommentTimestamp = commentsResponse.lastCommentTimestamp;
                }
            } catch (e) {
                if (abortController.signal.aborted) break;

                console.error('[subscribeToPublicComments] Fetch comments error', e);
                dispatch({
                    type: types.FETCH_PUBLIC_COMMENTS_FAILURE,
                    payload: e instanceof Error ? e.message : 'Failed to fetch comments',
                });
            }

            await new Promise(r => setTimeout(r, 2000));
        }
    };
};

export const unsubscribeFromPublicComments = () => {
    if (abortController) {
        abortController.abort();
        abortController = null;
    }
    return {
        type: types.UNSUBSCRIBE_PUBLIC_COMMENTS
    };
};

export const resetPublicComments = () => {
    return {
        type: types.RESET_PUBLIC_COMMENTS,
    };
};
