import { types } from './publicCommentsActions';
import { PublicCommentsState, Comment } from './publicCommentsTypes';

const initialState: PublicCommentsState = {
    comments: [],
    lastCommentTimestamp: 0,
    loading: false,
    error: null,
};

function publicCommentsReducer(state = initialState, action: {type: string, comments: Comment[], lastCommentTimestamp: number}): PublicCommentsState {
    switch (action.type) {
        case types.FETCH_PUBLIC_COMMENTS_SUCCESS:
            // avoid duplicates
            if (state.comments.find((comment: Comment) => action.comments.find((c: Comment) => c.uuid === comment.uuid))) {
                return state;
            }

            const comments = state.comments.concat(action.comments);

            return {
                ...state,
                loading: false,
                comments,
                lastCommentTimestamp: action.lastCommentTimestamp,
            };

        case types.RESET_PUBLIC_COMMENTS:
            return {
                ...initialState,
            };

        default:
            return state;
    }
}

export default publicCommentsReducer;
