import { Alert, Button, FormControl, LinearProgress, Paper, TextField, Typography } from "@mui/material";
import { withStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import styles from "./LoginPage-jss";
import { Grid } from "@mui/material";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { live4tvUserService } from "./live4tvUserService";
import { useNavigate, useSearchParams } from "react-router-dom";
import { connect } from "react-redux";
import { Auth, Profile } from "./userDTO";
import { setIsLoggedIn, setUserProfile } from "./userActions";
import useGTM from "packages/useGTM/useGTM";
import useCrashReport from "packages/useCrashReport/useCrashReport";

type Properties = {
    classes: any,
    auth: Auth,
    profile: Profile,
    setUserProfile: Function,
    setIsLoggedIn: Function,
}

function MagicLinkValidationPage(props: Properties) {
    const {
        classes,
        auth,
        profile,
        setUserProfile,
        setIsLoggedIn,
    } = props;

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const [info, setInfo] = useState('');
    const [success, setSuccess] = useState('');

    const [queryParameters] = useSearchParams()

    const { t } = useTranslation();

    const navigate = useNavigate();

    const gtm = useGTM({});

    const crashReporter = useCrashReport();

    useEffect(() => {
        validateMagicLink();
    }, []);

    useEffect(() => {
        console.debug('signedIn', auth.isLoggedIn);
        if (auth.isLoggedIn === true) {
            crashReporter.setUser(profile.code);
            navigate('/events');
        }
    }, [auth.isLoggedIn]);

    async function validateMagicLink() {
        setIsLoading(true);
        setError('');
        setSuccess('');
        setInfo(t('Wait while we are validating login link...'));

        try {
            const token = queryParameters.get('token');
            console.debug('token', token);

            if (!token) {
                throw new Error('Invalid token. Return to login page and try again.');
                return;
            }

            const response = await live4tvUserService.validateMagicLink(token);

            const user = await live4tvUserService.getUser(response.token);
            console.log('handleLogin().user', user);

            const profile: Profile = {
                firstname: user.firstname,
                lastnames: user.lastnames,
                code: user.code,
                email: user.email,
                username: user.username,
                token: response.token,
                roles: response.roles,
            }

            setUserProfile(profile);
            setIsLoggedIn(true);
            gtm.trackEvent('login', {
                user: {
                    id: profile.code
                },
            });

            setSuccess(t('Login successful.'));
        } catch (error: any) {
            console.debug('validateMagicLink().error', error);
            setError(t('Invalid token. Return to login page and try again.'));
        }

        setIsLoading(false);
        setInfo('');
    }

    return (
        <Grid container className={classNames(classes.root)} direction="row" alignItems="center" justifyContent="center">
            <Grid item xs={10} md={8} lg={6} container direction="column">
                <Paper className={classNames(classes.paperForm)} elevation={0} square>
                    {success ? <Alert severity="success" className={classNames(classes.alert)}>{success}</Alert> : (
                        <>
                            {error && <Alert severity="error" className={classNames(classes.alert)}>{error}</Alert> }
                            {info && <Alert severity="info" className={classNames(classes.alert)}>{info}</Alert> }
                            {isLoading ? <LinearProgress className={classNames(classes.progressBar)}/> : <Button fullWidth size="large" color="secondary" variant="contained" onClick={() => navigate('/')} >{t('Go to login page')}</Button> }
                        </>
                    )}

                </Paper>
            </Grid>
        </Grid>
    )
}

const mapStateToProps = function (state: any) {
    return {
        auth: state.auth,
        profile: state.user.profile
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        setIsLoggedIn: (signedIn: boolean) => dispatch(setIsLoggedIn(signedIn)),
        setUserProfile: (profile: Profile) => dispatch(setUserProfile(profile)),
    };
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(MagicLinkValidationPage));
