import {call, put, race, select, take, takeEvery} from "redux-saga/effects";
import {
    addLive4tvApiTargetRequest,
    checkpointRequired,
    checkpointSuccess, createAndStartLive4tvApiLiveStream,
    loginToLive4tvApiSuccess, sendACommentToLive4tvApi, sendACommentToLive4tvApiFail, stopLive4tvApiLiveStream,
    toggleComments,
    types,
    updateLoginUrl,
} from "./live4tvApiActions";
import {StreamTargetIntegration} from "../StreamTargetIntegration/streamTargetIntegrationDTO";
import {StreamTargetDialog} from "../StreamTargetDialog/streamTargetDialogDTO";
import {closeStreamTargetDialog, openStreamTargetDialog} from "../StreamTargetDialog/streamTargetDialogActions";
import {
    LiveStreamRequest,
    StreamTargetList,
    StreamTargetStatus,
    StreamTargetType
} from "../StreamTarget/streamTargetDTO";
import {
    Live4tvLiveStreamRequest,
    isInstanceOfLive4tvApiStreamTarget,
    Live4tvApiAuthRequest,
    Live4tvApiStreamTarget, Live4tvListStreamTargetsRequest, Live4tvRemoveStreamTargetRequest,
} from "./live4tvApiDTO";
import {Comment, CommentStatus} from "../Comments/CommentsDTO";
import {
    CreateAndStartLiveStreamRequest,
    CreateAndStartLiveStreamResponse,
    FetchLiveStreamCommentsResponse, Live4tvListAccountsResponse,
    live4tvLive4tvApiService,
    MuteCommentsRequest,
    SendACommentToLiveStreamRequest,
    SendACommentToLiveStreamResponse,
    StartListeningToLiveStreamCommentsRequest,
    StartListeningToLiveStreamCommentsResponse,
    StopListeningToLiveStreamCommentsRequest,
    StopListeningToLiveStreamCommentsResponse,
    StopLiveStreamRequest,
    StopLiveStreamResponse
} from "./live4tvLive4tvApiService";
import {v4 as uuidv4} from 'uuid';
import {
    addStreamTarget,
    createAndStartLiveStreamFail,
    createAndStartLiveStreamSuccess, removeStreamTarget,
    sendACommentFail,
    sendACommentSuccess,
    setStreamTargetStatus,
    startListeningToCommentsFail,
    startListeningToCommentsSuccess,
    stopListeningToCommentsFail, stopListeningToCommentsRequest,
    stopListeningToCommentsSuccess,
    stopLiveStreamFail,
    stopLiveStreamSuccess
} from "../StreamTarget/streamTargetActions";
import {addComment} from "../Comments/commentsActions";
import delay from "../../services/helpers/delay";
import {ChannelSettingsType} from "../Channel/ChannelDTO";
import {isInstanceOfInstagramChannelSettings} from "../InstagramSettings/InstagramSettingsDTO";

function* live4tvApiSaga() {
    yield takeEvery(types.ADD_LIVE4TV_TARGET_REQUEST, addLive4tvTargetRequested)
    yield takeEvery(types.ADD_LIVE4TV_TARGET_CANCELED, addLive4tvTargetCanceled)

    yield takeEvery(types.LIVE4TV_LOGIN_REQUEST, live4tvLoginRequested);

    yield takeEvery(types.LIVE4TV_CREATE_AND_START_LIVE_STREAM_REQUEST, createAndStartLive4tvApiLiveStreamRequested);

    yield takeEvery(types.LIVE4TV_STOP_LIVE_STREAM_REQUEST, stopLive4tvApiLiveStreamRequested);

    yield takeEvery(types.LIVE4TV_START_LISTENING_TO_COMMENTS_REQUEST, startListeningToCommentsRequested);

    yield takeEvery(types.LIVE4TV_STOP_LISTENING_TO_COMMENTS_REQUEST, stopListeningToCommentsRequested);

    yield takeEvery(types.LIVE4TV_SEND_A_COMMENT_REQUEST, sendACommentRequested);

    yield takeEvery(types.LIVE4TV_SUBSCRIBE_TO_COMMENTS_REQUEST, subscribeToCommentsRequested);

    yield takeEvery(types.LIVE4TV_TOGGLE_COMMENTS_REQUEST, toggleCommentsRequested);

    yield takeEvery(types.LIVE4TV_CHECKPOINT_REQUEST, checkpointRequiredRequested);

    yield takeEvery(types.LIVE4TV_LIST_TARGETS_REQUEST, listTargetsRequested);

    yield takeEvery(types.LIVE4TV_DELETE_TARGET_REQUEST, deleteTargetRequested);
}

function* addLive4tvTargetRequested(action: { type: string; streamTargetIntegration: StreamTargetIntegration }) {
    yield put(openStreamTargetDialog(getEmptyRequestDialog(action.streamTargetIntegration)));
}

function getEmptyRequestDialog(streamTargetIntegration: StreamTargetIntegration) {
    const requestDialog: StreamTargetDialog = {
        isOpen: true,
        type: StreamTargetType.LIVE4TVAPI,
        props: {
            streamTargetIntegration,
        },
    }

    return requestDialog;
}

function* addLive4tvTargetCanceled() {
    yield put(closeStreamTargetDialog());
}

function* live4tvLoginRequested(action: {type: string, payload: Live4tvApiAuthRequest}) {
    console.debug('[SAGA] Live4tvApi login', action);
    try {
        const uuid = uuidv4();
        const authorizationUrl: string = yield call(generateLoginUrl, action.payload, uuid);
        yield put(updateLoginUrl(authorizationUrl));

        const streamTarget: Live4tvApiStreamTarget = yield call(login, action.payload, authorizationUrl, uuid);
        yield put(loginToLive4tvApiSuccess(streamTarget));
        yield put(addStreamTarget(streamTarget));
        yield put(closeStreamTargetDialog());
    } catch (e) {

    }
}

async function generateLoginUrl(request: Live4tvApiAuthRequest, uuid: string): Promise<string> {
    return await live4tvLive4tvApiService.getUrlAuthorizationUrl(
        request.profile.token,
        request.network,
        uuid,
        request.profile.code
    );
}

async function login(request: Live4tvApiAuthRequest, authorizationUrl: string, uuid: string): Promise<Live4tvApiStreamTarget> {
    window.open(authorizationUrl, "_blank", "noreferrer");

    const maxAttempts = 60;
    const intervalMilliSeconds = 5000;

    for (let i = 0; i <= maxAttempts; i++) {
        console.debug({i}, {intervalMilliSeconds});

        try {
            let response = await live4tvLive4tvApiService.getSocialNetworkAccount(
                request.profile.token,
                request.profile.code,
                uuid,
            );

            return <Live4tvApiStreamTarget>{
                key: `${response.type}_${response.name}`,
                uuid: response.uuid,
                type: response.type,
                title: response.name,
                status: StreamTargetStatus.IDLE,
                commentStatus: CommentStatus.NOT_STARTED,
                isLive4tvApi: true,
            };
        } catch (e) {
            console.warn('Error getting access token', e);
        }

        await delay.sleep(intervalMilliSeconds);
    }

    throw new Error('Max attempts reached, live4TvApi getAccessToken');
}

function* checkpointRequiredRequested(action: {type: string, payload: Live4tvApiAuthRequest}) {
    console.debug('[SAGA] Checkpoint required', action);
    yield put(addLive4tvApiTargetRequest({
        code: action.payload.network,
        is_live4tv_api_integration: true,
    }));
    yield take(types.LIVE4TV_LOGIN_SUCCESS);

    yield put(checkpointSuccess(action.payload))
}

function refreshTargets(requestedTargets: Array<Live4tvApiStreamTarget>, refreshedStreamTargets: StreamTargetList): Array<Live4tvApiStreamTarget> {
    for (let i = 0; i < requestedTargets.length; i++) {
        const target = Object.values(refreshedStreamTargets).find((t) => t.type === requestedTargets[i].type && t.title === requestedTargets[i].title);
        if (isInstanceOfLive4tvApiStreamTarget(target)) {
            requestedTargets[i] = target;
        }
    }

    return requestedTargets;
}

function* createAndStartLive4tvApiLiveStreamRequested(action: {type: string, payload: Live4tvLiveStreamRequest}) {
    console.debug('[SAGA] Create and start live4tvApi live stream', action);
    const streamTargets: StreamTargetList = yield select((state) => state.streamTargets);
    action.payload.streamTargets = yield call(refreshTargets, action.payload.streamTargets, streamTargets);

    try {
        for (let streamTarget of action.payload.streamTargets) {
            streamTarget.status = StreamTargetStatus.STARTING;
            yield put(setStreamTargetStatus(streamTarget));
        }

        const request: CreateAndStartLiveStreamRequest = {
            title: action.payload.liveEvent.title,
            description: action.payload.liveEvent.details,
            accounts: action.payload.streamTargets.map((streamTarget: Live4tvApiStreamTarget) => { return streamTarget.uuid }),
        }
        console.debug('[SAGA] live4tvApi', 'createAndStartLive4tvApiLiveStreamRequested.request', request);

        const response: CreateAndStartLiveStreamResponse = yield call(
            live4tvLive4tvApiService.createAndStartLiveStream,
            action.payload.profile.token,
            action.payload.liveStreamUuid,
            request,
        );
        console.debug('[SAGA] live4tvApi', 'createAndStartLive4tvApiLiveStreamRequested.response', response);

        for (let account of response.accounts) {
            const streamTarget = action.payload.streamTargets.find((streamTarget: Live4tvApiStreamTarget) => { return streamTarget.uuid === account.uuid });
            if (!isInstanceOfLive4tvApiStreamTarget(streamTarget)) {
                continue;
            }
            if (account.code === 200) {
                streamTarget.transmission = {
                    url: account.streamUrl,
                    key: account.streamKey,
                }
                if (account.username) {
                    streamTarget.transmission.username = account.username;
                }
                if (account.password) {
                    streamTarget.transmission.password = account.password;
                }
                yield put(createAndStartLiveStreamSuccess({
                    token: action.payload.profile.token,
                    liveStreamUuid: action.payload.liveStreamUuid,
                    liveEvent: action.payload.liveEvent,
                    streamTarget,
                    settings: action.payload.settings,
                }));
            } else if (account.code === 302) {
                console.warn('[SAGA] live4tvApi', 'createAndStartLive4tvApiLiveStreamRequested.checkpoint', {...account});
                yield put(checkpointRequired(
                    {
                        network: streamTarget.type,
                        profile: action.payload.profile,
                        streamTarget,
                    },
                    'You must login again to your account to continue streaming',
                ));
                yield take(types.LIVE4TV_CHECKPOINT_SUCCESS);
                yield put(createAndStartLive4tvApiLiveStream({
                    profile: action.payload.profile,
                    streamTargets: [streamTarget],
                    liveEvent: action.payload.liveEvent,
                    liveStreamUuid: action.payload.liveStreamUuid,
                    settings: action.payload.settings,
                }));

            } else {
                console.error('[SAGA] live4tvApi', 'createAndStartLive4tvApiLiveStreamRequested.error', account.message);
                yield put(createAndStartLiveStreamFail({
                    token: action.payload.profile.token,
                    liveStreamUuid: action.payload.liveStreamUuid,
                    liveEvent: action.payload.liveEvent,
                    streamTarget: streamTarget,
                    settings: action.payload.settings,
                }));
            }
        }

        const successTargets = action.payload.streamTargets.filter((streamTarget: Live4tvApiStreamTarget) => { return response.accounts.find((account) => { return account.uuid === streamTarget.uuid && account.code === 200 }) });
        if (successTargets.length > 0) {
            yield put(toggleComments({
                profile: action.payload.profile,
                liveStreamUuid: action.payload.liveStreamUuid,
                liveEvent: action.payload.liveEvent,
                streamTargets: successTargets,
                settings: action.payload.settings,
            }));
        }
    } catch (e) {
        console.error('[SAGA] live4tvApi', 'createAndStartLive4tvApiLiveStreamRequested.error', e);
        for (let streamTarget of action.payload.streamTargets) {
            yield put(createAndStartLiveStreamFail({
                token: action.payload.profile.token,
                liveStreamUuid: action.payload.liveStreamUuid,
                liveEvent: action.payload.liveEvent,
                streamTarget: streamTarget,
                settings: action.payload.settings,
            }));
        }
    }
}

function* stopLive4tvApiLiveStreamRequested(action: {type: string, payload: Live4tvLiveStreamRequest}) {
    console.debug('[SAGA] Stop live4tvApi live stream', action);
    const streamTargets: StreamTargetList = yield select((state) => state.streamTargets);
    action.payload.streamTargets = yield call(refreshTargets, action.payload.streamTargets, streamTargets);

    try {
        for (let streamTarget of action.payload.streamTargets) {
            streamTarget.status = StreamTargetStatus.STOPPING;
            yield put(setStreamTargetStatus(streamTarget));
        }

        let saveLive = true;
        const settings = action.payload.settings[ChannelSettingsType.INSTAGRAM];
        if (isInstanceOfInstagramChannelSettings(settings)) {
            saveLive = settings.saveToIGTV;
        }

        const request: StopLiveStreamRequest = {
            accounts: action.payload.streamTargets.map((streamTarget: Live4tvApiStreamTarget) => { return streamTarget.uuid }),
            saveLive
        };

        console.debug('[SAGA] live4tvApi', 'stopLive4tvApiLiveStreamRequested.request', request);
        const response: StopLiveStreamResponse = yield call(
            live4tvLive4tvApiService.stopLiveStream,
            action.payload.profile.token,
            action.payload.liveStreamUuid,
            request,
        );

        for (let account of response.accounts) {
            const streamTarget = action.payload.streamTargets.find((streamTarget: Live4tvApiStreamTarget) => {
                return streamTarget.uuid === account.uuid
            });
            if (!isInstanceOfLive4tvApiStreamTarget(streamTarget)) {
                continue;
            }

            if (account.code === 200) {
                const liveStreamRequest: LiveStreamRequest = {
                    token: action.payload.profile.token,
                    liveStreamUuid: action.payload.liveStreamUuid,
                    liveEvent: action.payload.liveEvent,
                    streamTarget,
                    settings: action.payload.settings,
                };
                yield put(stopLiveStreamSuccess(liveStreamRequest));

            } else if (account.code === 302) {
                console.warn('[SAGA] live4tvApi', 'createAndStartLive4tvApiLiveStreamRequested.checkpoint', {...account});
                yield put(checkpointRequired(
                    {
                        network: streamTarget.type,
                        profile: action.payload.profile,
                        streamTarget: streamTarget,
                    },
                    'You must login again to your account to continue streaming',
                ));
                yield take(types.LIVE4TV_CHECKPOINT_SUCCESS);
                yield put(stopLive4tvApiLiveStream({
                    profile: action.payload.profile,
                    liveStreamUuid: action.payload.liveStreamUuid,
                    liveEvent: action.payload.liveEvent,
                    settings: action.payload.settings,
                    streamTargets: [streamTarget],
                }));

            } else {
                console.error('[SAGA] live4tvApi', 'stopLive4tvApiLiveStreamRequested.error', account.message);
                yield put(stopLiveStreamFail({
                    token: action.payload.profile.token,
                    liveStreamUuid: action.payload.liveStreamUuid,
                    liveEvent: action.payload.liveEvent,
                    streamTarget,
                    settings: action.payload.settings,
                }));
            }
        }

    } catch (e) {
        console.error('[SAGA] live4tvApi', 'stopLive4tvApiLiveStreamRequested.error', e);
        for (let streamTarget of action.payload.streamTargets) {
            const liveStreamRequest: LiveStreamRequest = {
                token: action.payload.profile.token,
                liveStreamUuid: action.payload.liveStreamUuid,
                liveEvent: action.payload.liveEvent,
                streamTarget,
                settings: action.payload.settings,
            };
            yield put(stopLiveStreamFail(liveStreamRequest));
            // when the stop live fail, we must stop to listening comments, due the live is considered finished, and at moment there no way to point recovered to replay the action to stop trying to stop with success the live.
            yield put(stopListeningToCommentsRequest(liveStreamRequest));
        }
    }
}

function* subscribeToCommentsRequested(action: {type: string, payload: Live4tvLiveStreamRequest}) {
    console.debug('[SAGA] subscribeToCommentsRequested', action);
    yield race({
        worker: call(fetchCommentsWorker, action),
        cancel: take(types.LIVE4TV_STOP_SUBSCRIPTION_TO_COMMENTS_REQUEST),
    });
}

function* fetchCommentsWorker(action: {type: string, payload: Live4tvLiveStreamRequest}) {
    console.debug('[SAGA] Fetch comments', action);
    let lastCommentTimestamp = 0;

    while (true) {
        console.debug('[SAGA] Fetching comments', lastCommentTimestamp);
        try {
            const commentsResponse: FetchLiveStreamCommentsResponse = yield call(
                live4tvLive4tvApiService.fetchLiveStreamComments,
                action.payload.profile.token,
                action.payload.liveStreamUuid,
                lastCommentTimestamp,
            );
            console.debug('[SAGA] live4tvApi', 'fetchCommentsWorker.response', commentsResponse);

            if (commentsResponse.count > 0) {
                for (let i = 0; i < commentsResponse.count; i++) {
                    let comment: Comment = {
                        streamTargetType: commentsResponse.comments[i].socialNetwork,
                        key: commentsResponse.comments[i].uuid,
                        username: commentsResponse.comments[i].user.username,
                        photo: commentsResponse.comments[i].user.photo,
                        message: commentsResponse.comments[i].message,
                        time: new Date(commentsResponse.comments[i].sentAt * 1000),
                    };
                    lastCommentTimestamp = commentsResponse.lastCommentTimestamp;
                    yield put(addComment(action.payload.liveEvent, comment));
                }
            }
        } catch (e) {
            console.error('[SAGA] Fetch comments error', e);
        }

        yield delay.sleep(2000);
    }
}

function* startListeningToCommentsRequested(action: {type: string, payload: Live4tvLiveStreamRequest}) {
    console.debug('[SAGA] Start listening to comments', action);
    try {
        const request: StartListeningToLiveStreamCommentsRequest = {
            accounts: action.payload.streamTargets.map((streamTarget: Live4tvApiStreamTarget) => { return streamTarget.uuid }),
        }
        console.debug('[SAGA] live4tvApi', 'startListeningToCommentsRequested.request', request);

        const response: StartListeningToLiveStreamCommentsResponse = yield call(
            live4tvLive4tvApiService.startListeningToComments,
            action.payload.profile.token,
            action.payload.liveStreamUuid,
            request,
        );
        console.debug('[SAGA] live4tvApi', 'startListeningToCommentsRequested.response', response);

        for (let account of response.accounts) {
            const streamTarget = action.payload.streamTargets.find((streamTarget: Live4tvApiStreamTarget) => { return streamTarget.uuid === account.uuid });
            console.debug('[SAGA] live4tvApi', 'startListeningToCommentsRequested.streamTarget', streamTarget);
            if (!isInstanceOfLive4tvApiStreamTarget(streamTarget)) {
                continue;
            }
            if (account.code === 200) {
                yield put(startListeningToCommentsSuccess({
                    token: action.payload.profile.token,
                    liveStreamUuid: action.payload.liveStreamUuid,
                    liveEvent: action.payload.liveEvent,
                    streamTarget,
                    settings: action.payload.settings,
                }));
            } else {
                console.error('[SAGA] live4tvApi', 'startListeningToCommentsRequested.error', account.message);
                yield put(startListeningToCommentsFail({
                    token: action.payload.profile.token,
                    liveStreamUuid: action.payload.liveStreamUuid,
                    liveEvent: action.payload.liveEvent,
                    streamTarget,
                    settings: action.payload.settings,
                }));
            }
        }
    } catch (e) {
        console.error('[SAGA] live4tvApi', 'startListeningToCommentsRequested.error', e);
        for (let streamTarget of action.payload.streamTargets) {
            yield put(startListeningToCommentsFail({
                token: action.payload.profile.token,
                liveStreamUuid: action.payload.liveStreamUuid,
                liveEvent: action.payload.liveEvent,
                streamTarget,
                settings: action.payload.settings,
            }));
        }
    }
}

function* stopListeningToCommentsRequested(action: {type: string, payload: Live4tvLiveStreamRequest}) {
    console.debug('[SAGA] Stop listening to comments', action);
    try {
        const request: StopListeningToLiveStreamCommentsRequest = {
            accounts: action.payload.streamTargets.map((streamTarget: Live4tvApiStreamTarget) => { return streamTarget.uuid }),
        }
        console.debug('[SAGA] live4tvApi', 'stopListeningToCommentsRequested.request', request);

        const response: StopListeningToLiveStreamCommentsResponse = yield call(
            live4tvLive4tvApiService.stopListeningToComments,
            action.payload.profile.token,
            action.payload.liveStreamUuid,
            request,
        );
        console.debug('[SAGA] live4tvApi', 'stopListeningToCommentsRequested.response', response);

        for (let account of response.accounts) {
            const streamTarget = action.payload.streamTargets.find((streamTarget: Live4tvApiStreamTarget) => { return streamTarget.uuid === account.uuid });
            if (!isInstanceOfLive4tvApiStreamTarget(streamTarget)) {
                continue;
            }
            if (account.code === 200) {
                yield put(stopListeningToCommentsSuccess({
                    token: action.payload.profile.token,
                    liveStreamUuid: action.payload.liveStreamUuid,
                    liveEvent: action.payload.liveEvent,
                    streamTarget,
                    settings: action.payload.settings,
                }));
            } else {
                console.error('[SAGA] live4tvApi', 'stopListeningToCommentsRequested.error', account.message);
                yield put(stopListeningToCommentsFail({
                    token: action.payload.profile.token,
                    liveStreamUuid: action.payload.liveStreamUuid,
                    liveEvent: action.payload.liveEvent,
                    streamTarget,
                    settings: action.payload.settings,
                }));
            }
        }

    } catch (e) {
        console.error('[SAGA] live4tvApi', 'stopListeningToCommentsRequested.error', e);
        for (let streamTarget of action.payload.streamTargets) {
            yield put(stopListeningToCommentsFail({
                token: action.payload.profile.token,
                liveStreamUuid: action.payload.liveStreamUuid,
                liveEvent: action.payload.liveEvent,
                streamTarget,
                settings: action.payload.settings,
            }));
        }
    }
}

function* sendACommentRequested(action: {type: string, payload: Live4tvLiveStreamRequest, message: string}) {
    console.debug('[SAGA] Send a comment', action);
    const streamTargets: StreamTargetList = yield select((state) => state.streamTargets);
    action.payload.streamTargets = yield call(refreshTargets, action.payload.streamTargets, streamTargets);

    try {
        const request: SendACommentToLiveStreamRequest = {
            message: action.message,
        }
        console.debug('[SAGA] live4tvApi', 'sendACommentRequested.request', request);

        const response: SendACommentToLiveStreamResponse = yield call(
            live4tvLive4tvApiService.sendACommentToLiveStream,
            action.payload.profile.token,
            action.payload.liveStreamUuid,
            request,
        );
        console.debug('[SAGA] live4tvApi', 'sendACommentRequested.response', response);

        for (let account of response.accounts) {
            const streamTarget = action.payload.streamTargets.find((streamTarget: Live4tvApiStreamTarget) => {
                return streamTarget.uuid === account.uuid
            });
            if (!isInstanceOfLive4tvApiStreamTarget(streamTarget)) {
                continue;
            }

            if (account.code === 200) {
                yield put(sendACommentSuccess({
                    token: action.payload.profile.token,
                    liveStreamUuid: action.payload.liveStreamUuid,
                    liveEvent: action.payload.liveEvent,
                    streamTarget,
                    settings: action.payload.settings,
                }));
            } else if (account.code === 302) {
                console.warn('[SAGA] live4tvApi', 'createAndStartLive4tvApiLiveStreamRequested.checkpoint', {...account});
                yield put(checkpointRequired(
                    {
                        network: streamTarget.type,
                        profile: action.payload.profile,
                        streamTarget: streamTarget,
                    },
                    'You must login again to your account to continue streaming',
                ));
                yield take(types.LIVE4TV_CHECKPOINT_SUCCESS);
                yield put(sendACommentToLive4tvApi({
                    profile: action.payload.profile,
                    liveStreamUuid: action.payload.liveStreamUuid,
                    liveEvent: action.payload.liveEvent,
                    streamTargets: [streamTarget],
                    settings: action.payload.settings,
                }, action.message));
            } else {
                yield put(sendACommentToLive4tvApiFail(action.payload, action.message));
            }
        }


    } catch (e) {
        console.error('[SAGA] live4tvApi', 'sendACommentRequested.error', e);

        yield put(sendACommentFail({
            token: action.payload.profile.token,
            liveStreamUuid: action.payload.liveStreamUuid,
            liveEvent: action.payload.liveEvent,
            streamTarget: action.payload.streamTargets[0],
            settings: action.payload.settings,
        }));
    }
}

function* toggleCommentsRequested(action: {type: string, payload: Live4tvLiveStreamRequest}) {
    const streamTargets: StreamTargetList = yield select((state) => state.streamTargets);
    action.payload.streamTargets = yield call(refreshTargets, action.payload.streamTargets, streamTargets);

    const request: MuteCommentsRequest = {
        accounts: action.payload.streamTargets
            .filter((streamTarget: Live4tvApiStreamTarget) => { return streamTarget.status === StreamTargetStatus.LIVE })
            .map((streamTarget: Live4tvApiStreamTarget) => { return streamTarget.uuid }),
    }

    if (request.accounts.length === 0) {
        console.warn('[SAGA] live4tvApi', 'toggleCommentsRequested', 'No accounts to mute');
    }

    try {
        const settings = action.payload.settings[ChannelSettingsType.INSTAGRAM];
        if (settings && isInstanceOfInstagramChannelSettings(settings)) {
            if (settings.allowComments) {
                yield call(live4tvLive4tvApiService.unmute, action.payload.profile.token, action.payload.liveStreamUuid, request);
            } else {
                yield call(live4tvLive4tvApiService.mute, action.payload.profile.token, action.payload.liveStreamUuid, request);
            }
        }
    } catch (e) {
        console.error('[SAGA] live4tvApi', 'toggleCommentsRequested.error', e);
    }
}

function* listTargetsRequested(action: { type: string, payload: Live4tvListStreamTargetsRequest }) {
    const streamTargets: StreamTargetList = yield select((state) => state.streamTargets);

    try {
        const response: Live4tvListAccountsResponse = yield call(
            live4tvLive4tvApiService.listAccounts,
            action.payload.profile.token,
            action.payload.profile.code,
        );
        console.debug('[SAGA] live4tvApi', 'listTargetsRequested.response', response);

        for (let account of response) {
            const streamTarget = Object.values(streamTargets).find((t) => {
                return t.type === account.type && t.title === account.name
            });
            if (streamTarget !== undefined && !isInstanceOfLive4tvApiStreamTarget(streamTarget)) {
                console.warn('[SAGA] live4tvApi', 'listTargetsRequested', 'Invalid stream target', streamTarget);
                continue;
            }

            if (streamTarget && streamTarget.uuid !== account.uuid) {
                yield put(removeStreamTarget(streamTarget));
            }

            yield put(addStreamTarget(<Live4tvApiStreamTarget>{
                key: `${account.type}_${account.name}`,
                uuid: account.uuid,
                type: account.type,
                title: account.name,
                status: StreamTargetStatus.IDLE,
                commentStatus: CommentStatus.NOT_STARTED,
                isLive4tvApi: true,
            }));
        }
    } catch (e) {
        console.error('[SAGA] live4tvApi', 'listTargetsRequested.error', e);
    }
}

function* deleteTargetRequested(action: {type: string, payload: Live4tvRemoveStreamTargetRequest}) {
    try {
        yield call(
            live4tvLive4tvApiService.deleteAccount,
            action.payload.profile.token,
            action.payload.streamTarget.uuid
        );
    } catch (e) {
        console.error('[SAGA] live4tvApi', 'deleteTargetRequested.error', e);
    }
}

export default live4tvApiSaga;
