import React from "react";
import {Routes, Route, HashRouter as Router} from "react-router-dom";

// for some reason if login is not at this place, the app fails to load and stays with a blank screen (error Cannot access 'types' before initialization” error)
import LoginPage from "../features/User/LoginPage";
import SignupPage from "../features/User/SignupPage";

import Events from "../features/Events/ListEventsPage";
import Layout from "../components/Layout";
import Studio from "../features/Studio/StudioPage";
import NewEvent from "../features/Events/EventPage";
import StreamTargetPage from "../features/StreamTarget/StreamTargetPage";
import ConfigsPage from "../features/Config/ConfigsPage";
import PlanPage from "../features/Plan/PlanPage";
import Logout from "../features/User/LogoutPage";
import AppStructure from "../components/Layout/AppStructure";
import EmptyAppStructure from "../components/Layout/EmptyAppStructure";
import SubscriptionSuccessPage from "../features/Plan/SubscriptionSuccessPage";
import LiveStreamDataPage from "../features/LiveStreamData/LiveStreamDataPage";
import AffiliatePage from "../features/Affiliate/AffiliatePage";
import PublicCommentsPage from "../features/PublicComments/PublicCommentsPage";
import WidgetsAndOverlaysPage from "../features/WidgetsAndOverlays/WidgetsAndOverlaysPage";
import MagicLinkLoginPage from "features/User/MagicLinkLoginPage";
import MagicLinkValidationPage from "features/User/MagicLinkValidationPage";
import MagicLinkSignupPage from "features/User/MagicLinkSignupPage";
import { StreamTargetAccountSuccessPage } from "features/StreamTarget/StreamTargetAccountSuccessPage";

function App() {
    return (
        // https://reactrouter.com/web/api/Switch
        <Router>
            <Layout>
                <Routes>
                    <Route element={<EmptyAppStructure />}>
                        <Route exact path="/" element={<MagicLinkLoginPage />} />
                        <Route path="/login" element={<MagicLinkLoginPage />} />
                        <Route path="/signup" element={<MagicLinkSignupPage />} />
                        <Route path="/login-magic-link" element={<MagicLinkValidationPage />} />

                        {/* @todo remove these Legacy routes when we certify that the magic link is not causing issues */}
                        <Route path="/login-legacy" element={<LoginPage />} />
                        <Route path="/signup-legacy" element={<SignupPage />} />

                        <Route path="/logout" element={<Logout />} />

                        <Route path="/stream-target/success" element={<StreamTargetAccountSuccessPage />} />

                        <Route path="/widget">
                            <Route path="/widget/comments/user/:uuid" element={<PublicCommentsPage />} />
                        </Route>
                    </Route>

                    <Route element={<AppStructure />}>
                        <Route path="/events/new" element={<NewEvent />} />
                        <Route path="/events/:eventCode" element={<NewEvent />} />
                        <Route path="/events" element={<Events />} />
                        <Route path="/live-streams-data" element={<LiveStreamDataPage />} />
                        <Route path="/stream-targets" element={<StreamTargetPage />} />

                        <Route path="/studio/:eventCode" element={<Studio />} />

                        <Route path="/configs" element={<ConfigsPage />} />

                        <Route path="/plan" element={<PlanPage />} />
                        <Route path="/affiliate" element={<AffiliatePage />} />
                        <Route path="/subscription/success" element={<SubscriptionSuccessPage />} />

                        <Route path="/widgets-and-overlays" element={<WidgetsAndOverlaysPage />} />
                    </Route>
                </Routes>
            </Layout>
        </Router>
    );
}

export default App;
