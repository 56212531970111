import {httpClient} from "../../services/live4tv-client/http-client";
import {AxiosResponse} from "axios";
import {Profile} from "./userDTO";

const config = require('config/app.config');
const LIVE4_TV_BASE_URL = config.live4tv.api_url;
const LIVE4_TV_STUDIO_BASE_URL = config.live4tv.studio_url;

interface StreamNetwork {
    code: string,
}

interface AuthResponse {
    status: string,
    token: string,
    roles: Array<string>,
}

export const live4tvUserService = {
    async signUp(user: any): Promise<AuthResponse> {
        const url = '/api/v1/signup';

        const response = await httpClient.post<AuthResponse>(
            url,
            {
                first_name: user.firstName,
                last_names: user.lastNames,
                email: user.username,
                password: user.password,
                segment: user.segment,
                persona: user.persona,
                whatsapp: user.whatsapp,
            },
            {
                baseURL: LIVE4_TV_BASE_URL,
            }
        )

        return response.data;
    },

    async login(username: string, password: string): Promise<AuthResponse> {
        const url = '/api/v1/login';
        const response = await httpClient.post<AuthResponse>(
            url,
            {
                username: username,
                password: password,
            },
            {
                baseURL: LIVE4_TV_BASE_URL,
            }
        )

        return response.data;
    },

    async getUser(token: string): Promise<Profile> {
        const url = '/api/v1/user';

        const response = await httpClient.get<Profile>(
            url,
            {
                baseURL: LIVE4_TV_BASE_URL,
                headers: { 'Authorization': 'Bearer ' + token }
            }
        );

        return response.data;
    },

    async getStreamNetworks(): Promise<AxiosResponse<Array<StreamNetwork>>> {
        const url = '/api/v1/stream-networks';

        return await httpClient.get<Array<StreamNetwork>>(url, {
            baseURL: LIVE4_TV_BASE_URL,
        });
    },

    async updateEventStreamTargets(token: string, eventSlug: string, targets: Array<any>) {
        const url = `/api/v2/events/${eventSlug}/stream-targets`;

        return await httpClient.put(
            url,
            targets,
            {
                baseURL: LIVE4_TV_BASE_URL,
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            }
        );
    },

    async generateMagicLink(email: string) {
        const redirectUrl = `${LIVE4_TV_STUDIO_BASE_URL}/#/login-magic-link`;

        const url = `/api/v1/magic-link/login?redirectUrl=${encodeURIComponent(redirectUrl)}`;

        return await httpClient.post(
            url,
            { email },
            { baseURL: LIVE4_TV_BASE_URL }
        );
    },

    async validateMagicLink(token: string): Promise<AuthResponse> {
        const url = `/api/v1/magic-link/validate`;

        const response = await httpClient.post<AuthResponse>(
            url,
            { token },
            { baseURL: LIVE4_TV_BASE_URL }
        );

        return response.data;
    },

    async signUpMagicLink(user: any) {
        const redirectUrl = `${LIVE4_TV_STUDIO_BASE_URL}/#/login-magic-link`;

        const url = `/api/v1/magic-link/signup?redirectUrl=${encodeURIComponent(redirectUrl)}`;

        return await httpClient.post(
            url,
            {
                first_name: user.firstName,
                last_names: user.lastNames,
                email: user.username,
                segment: user.segment,
                persona: user.persona,
                whatsapp: user.whatsapp,
            },
            { baseURL: LIVE4_TV_BASE_URL }
        );
    }
};
