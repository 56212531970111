import { httpClient } from "../../services/live4tv-client/http-client";
import { Comment, CommentsResponse } from "./publicCommentsTypes";

const config = require('config/app.config');
const LIVE4_TV_BASE_URL = config.live4tv.api_url;

const publicCommentsService = {
    async fetchPublicComments(uuid: string, lastCommentTimestamp: number): Promise<CommentsResponse> {
        const url = `/api/v2/stream-network/proxy?cmd=user/${uuid}/comments?lastCommentTimestamp=${lastCommentTimestamp}`;

        const response = await httpClient.get<CommentsResponse>(
            url,
            {
                baseURL: LIVE4_TV_BASE_URL,
            }
        );

        return response.data;
    },
};

export default publicCommentsService;
