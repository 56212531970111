import {useEffect, useState} from "react";
import {withStyles} from "@mui/styles";
import {connect} from "react-redux";
import {Grid, IconButton} from "@mui/material";
import {Facebook, FullscreenExit, FullscreenSharp, Instagram, YouTube, ZoomIn, ZoomOut} from "@mui/icons-material";
import classNames from "classnames";
import {StreamTargetType} from "../StreamTarget/streamTargetDTO";
import {SiTiktok, SiTwitch} from "react-icons/si";
import styles from "./PublicCommentsPage-jss";
import { Comment } from "./publicCommentsTypes";
import { resetPublicComments, subscribeToPublicComments, unsubscribeFromPublicComments } from "./publicCommentsActions";
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { imageExists } from "services/helpers/image-exists";
import { useParams } from "react-router-dom";
import { generateColorHashFromString } from "services/helpers/generateColorHashFromString";

type Properties = {
    classes: any,
    comments: Comment[],
    subscribeToPublicComments: (userUuid: string) => void,
    resetPublicComments: () => void,
    unsubscribeFromPublicComments: () => void,
}

function PublicCommentsPage(props: Properties) {
    const {
        classes,
        comments,
        subscribeToPublicComments,
        resetPublicComments,
        unsubscribeFromPublicComments,
    } = props;
    const [fontSize, setFontSize] = useState(16);
    const [isFullScreen, setFullScreen] = useState(false);

    const { uuid } = useParams();

    useEffect(() => {
        resetPublicComments();

        return () => {
            unsubscribeFromPublicComments();
        };
    }, []);

    useEffect(() => {
        if (uuid) {
            subscribeToPublicComments(uuid);
        }
    }, [uuid]);

    useEffect(() => {
        if (Object.keys(comments).length > 0) {
            const chatContainer = document.getElementById('chat');
            if (chatContainer) {
                chatContainer.scrollTop = chatContainer.scrollHeight;
            }
        }
    }, [comments]);

    const increaseFontSize = () => {
        setFontSize(fontSize + 2);
    };

    const decreaseFontSize = () => {
        setFontSize(fontSize - 2);
    };

    const toggleFullScreen = () => {
        if (document.fullscreenElement) {
            document.exitFullscreen();
            setFullScreen(false);
        } else {
            document.documentElement.requestFullscreen();
            setFullScreen(true);
        }
    };

    const getIcon = (type: StreamTargetType) => {
        switch (type) {
            case StreamTargetType.FACEBOOK:
                return <Facebook />
            case StreamTargetType.INSTAGRAM:
                return  <Instagram />
            case StreamTargetType.YOUTUBE:
                return  <YouTube />
            case StreamTargetType.TIKTOK:
                return  <SiTiktok />
            case StreamTargetType.TWITCH:
                return  <SiTwitch />
            default:
                return <></>
        }
    }

    const getComments = () => {
        let htmlComments: Array<any> = [];

        if (Object.keys(comments).length > 0) {
            Object.values(comments).forEach((comment: Comment) => {
                htmlComments.push(
                    <div className={classNames(classes.message)}>
                        {/* <div className={classNames(classes.time)}>
                            {new Date(comment.sentAt).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' })}
                        </div> */}
                        {comment.user?.photo
                            && comment.user.photo !== 'no_profile_picture_url'
                            && imageExists(comment.user.photo)
                        ? (
                            <img
                                className={classNames(classes.profilePic)}
                                src={comment.user?.photo}
                                alt={comment.user?.username}
                            />
                        ) : (
                            <div className={classNames(classes.profilePic)}>&nbsp;</div>
                        )}

                        <span className={classNames(classes.socialIcon)} style={{ fontSize: `${fontSize}px` }}>
                            {getIcon(comment.socialNetwork)}
                        </span>

                        {/* <span className={classes.username} style={{ fontSize: `${fontSize}px` }}>

                        </span> */}

                        <span className={classes.messageText} style={{ fontSize: `${fontSize}px`, color: `#${generateColorHashFromString(comment.user?.username || '')}` }}>
                            {comment.user?.username}: {comment.message}
                        </span>
                    </div>
                );
            });
        }

        return htmlComments;
    }

    return (
        <Grid
            container
            direction="row"
            sx={{ backgroundColor: 'transparent', maxHeight: '100vh' }}
            xs={12}
            spacing={2}
        >
            <Grid item xs={12}>
                <div className={classNames(classes.iconButtons)}>
                    <IconButton color="primary" onClick={increaseFontSize}>
                        <ZoomIn />
                    </IconButton>
                    <IconButton color="primary" onClick={decreaseFontSize}>
                        <ZoomOut />
                    </IconButton>
                    <IconButton color="primary" onClick={toggleFullScreen}>
                        {isFullScreen ? <FullscreenExit /> : <FullscreenSharp />}
                    </IconButton>
                </div>
            </Grid>

            <Grid item className={classNames(classes.chatContainer)} id="chat" xs={12}>
                {getComments()}
            </Grid>
        </Grid>
    );
}

const mapStateToProps = function (state: any) {
    return {
        comments: state.publicComments.comments,
        profile: state.user.profile,
    }
}

function mapDispatchToProps(dispatch: ThunkDispatch<any, any, AnyAction>) {
    return {
        subscribeToPublicComments: (userUuid: string) => dispatch(subscribeToPublicComments(userUuid)),
        resetPublicComments: () => dispatch(resetPublicComments()),
        unsubscribeFromPublicComments: () => dispatch(unsubscribeFromPublicComments()),
    };
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(PublicCommentsPage))
