import React from "react";
import Page from "components/Page/Page";
import { useTranslation } from "react-i18next";
import { Button, Card, CardActions, CardContent, Grid, Typography } from "@mui/material";
import { OpenInNew } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { Profile } from "features/User/userDTO";

type Properties = {
    profile: Profile,
}

function WidgetsAndOverlaysPage(props: Properties) {
    const { profile } = props;

    const { t } = useTranslation();

    const navigate = useNavigate();

    return (
        <Page title={t('Widgets & Overlays')}>
            <Grid container
              direction="row"
              alignItems="flex-start"
              xs={12}>
                <Grid item xs={3}>
                    <Card>
                        <CardContent>
                            <Typography variant="h5" component="div">
                                  {t('Live Stream Comments')}
                            </Typography>
                            <Typography sx={{ color: 'text.secondary', mb: 1.5 }}>{t('Widget & Overlay')}</Typography>
                            <Typography variant="body1">
                                {t('Open as a separated window in a big screen or on the mobile phone. Or add the link as an overlay to your OBS Studio')}.
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Button size="small" variant="contained" color="secondary" startIcon={<OpenInNew />} onClick={() => window.open(`${window.location.origin}#/widget/comments/user/${profile.code}`, '_blank')}>{t('Open comments link')}</Button>
                        </CardActions>
                    </Card>
                </Grid>
            </Grid>
        </Page>
    );
}

const mapStateToProps = function (state: any) {
    return {
        profile: state.user.profile,
    };
};

export default (connect(mapStateToProps)(WidgetsAndOverlaysPage));
