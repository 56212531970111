import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import {Fragment} from "react";
import {Link} from "react-router-dom";
import {ChatBubble, Dataset, Logout, Repeat, Share, Videocam, Widgets, YouTube} from "@mui/icons-material";
import {useTranslation} from "react-i18next";
import classNames from "classnames";
import styles from "./MainMenu-jss";
import {withStyles} from "@mui/styles";
import {connect} from "react-redux";
import {Auth, Profile} from "../../features/User/userDTO";
import {Chip, Typography} from "@mui/material";
import {MainMenuUIState} from "./MainMenuUIReducer";
import "/node_modules/flag-icons/css/flag-icons.min.css";
import {BsHeart} from "react-icons/bs";

const drawerWidth = 280;

type Properties = {
    classes: any,
    profile: Profile,
    auth: Auth,
    ui: MainMenuUIState,
}

function MainMenu(props: Properties) {
    const {
        classes,
        profile,
        auth,
        ui,
    } = props;

    const [mobileOpen, setMobileOpen] = React.useState(false);

    const {t, i18n} = useTranslation();

    const changeLanguage = (e: any, lng: string) => {
        e.preventDefault();
        i18n.changeLanguage(lng);
    };

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const drawer = (
        <div>
            <Toolbar />
            <Divider />
            <List>
                <ListItem button key="events" component={Link} to="/events">
                    <ListItemIcon><Videocam /></ListItemIcon>
                    <ListItemText primary={t('Events')} />
                </ListItem>

                <ListItem button key="stream-targets" component={Link} to="/stream-targets">
                    <ListItemIcon><Share /></ListItemIcon>
                    <ListItemText primary={t('Destinations')} />
                </ListItem>

                <ListItem button key="live-stream-data" component={Link} to="/live-streams-data">
                    <ListItemIcon><Dataset /></ListItemIcon>
                    <ListItemText primary={t('Live Stream Historical')} />
                </ListItem>

                <ListItem button key="widgets-and-overlays" component={Link} to="/widgets-and-overlays">
                    <ListItemIcon><Widgets /></ListItemIcon>
                    <ListItemText primary={t('Widgets & Overlays')} />
                    <ListItemText primary={<Chip size="small" label={ t('new') } color="secondary" />} />
                </ListItem>
            </List>

            <Divider />

            <List>
                <ListItem button key="plan" component={Link} to="/plan">
                    <ListItemIcon><Repeat /></ListItemIcon>
                    <ListItemText primary={t('Subscription')} />
                </ListItem>
            </List>

            <Divider />
            <List>
                <ListItem button key="plan" component={Link} to="/affiliate">
                    <ListItemIcon><BsHeart /></ListItemIcon>

                    <ListItemText primary={t('Refer & earn 10%')} />&nbsp;
                    {/* <ListItemText primary={<Chip size="small" label={ t('$') } color="primary" />} /> */}

                </ListItem>
            </List>

            <Divider />
            <List>
                <ListItem button key="video-tutorials" component={Link} to='https://www.youtube.com/channel/UC7fyO0LbAJt9KsE4fhQmYuw' target="_blank">
                    <ListItemIcon><YouTube /></ListItemIcon>
                    <ListItemText primary={t('Tutorials')} />
                </ListItem>
                <ListItem button key="contact" component={Link} to="https://www.live4.tv/pt/contato" target="_blank">
                    <ListItemIcon><ChatBubble /></ListItemIcon>
                    <ListItemText primary={t('Contact')} />
                </ListItem>
            </List>

            <Divider />

            <List>
                <ListItem button key="logout" component={Link} to='/logout'>
                    <ListItemIcon><Logout /></ListItemIcon>
                    <ListItemText primary={t('Logout')} />
                </ListItem>
            </List>


            <Divider />
            <List dense={true} style={{marginTop: `auto`}} className={classNames(classes.footer)} >

                {profile && (
                    <ListItem>
                        <ListItemText primary={t('Your Email')} secondary={profile.email} />
                    </ListItem>
                )}
                <ListItem>
                    <a className={classNames(classes.flag)} href="#" onClick={(e) => changeLanguage(e, 'en')}><span className="fi fi-us"></span></a>&nbsp;
                    <a className={classNames(classes.flag)} href="#" onClick={(e) => changeLanguage(e,'pt')}><span className="fi fi-br"></span></a>
                </ListItem>
            </List>
        </div>
    );

    return (
        <Fragment>
            {auth.isLoggedIn && (
                <Fragment>
                    <AppBar
                        position="fixed"
                        sx={{
                            width: { md: `calc(100% - ${drawerWidth}px)` },
                            ml: { md: `${drawerWidth}px` },
                        }}
                        style={{backgroundColor: '#fff'}}
                    >
                        <Toolbar>
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                edge="start"
                                onClick={handleDrawerToggle}
                                sx={{ mr: 2, display: { md: 'none' } }}
                            >
                                <MenuIcon />
                            </IconButton>

                            <Typography variant="h6" noWrap component="div">
                                { ui.title }
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <Box
                        component="nav"
                        sx={{ width: { sm: drawerWidth }, flexShrink: { md: 0 } }}
                        aria-label="mailbox folders"
                    >
                        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                        <Drawer
                            variant="temporary"
                            open={mobileOpen}
                            onClose={handleDrawerToggle}
                            ModalProps={{
                                keepMounted: true, // Better open performance on mobile.
                            }}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                                '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                            }}
                        >
                            {drawer}
                        </Drawer>
                        <Drawer
                            variant="permanent"
                            sx={{
                                display: { xs: 'none', md: 'block' },
                                '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                            }}
                            open
                        >
                            {drawer}
                        </Drawer>
                    </Box>
                </Fragment>
            )}
        </Fragment>
    );
}

const mapStateToProps = function (state: any) {
    return {
        auth: state.auth,
        profile: state.user.profile,
        ui: state.mainMenuUI,
    }
}

export default withStyles(styles)(connect(mapStateToProps)(MainMenu));
