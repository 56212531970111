import {types} from "./live4tvApiActions";
import {Live4tvApiAuthRequest, Live4tvApiStreamTarget} from "./live4tvApiDTO";

export interface State {
    isLoading: boolean,
    errorMessage: string|null,
    infoMessage: string|null,
    streamTarget?: Live4tvApiStreamTarget,
    loginUrl: string|undefined,
}

const initialState: State = {
    isLoading: false,
    errorMessage: null,
    infoMessage: null,
    loginUrl: undefined,
}

export function live4tvApiIntegration(state: State = initialState, action: {type: string, errorMessage?: string, infoMessage?: string, payload?: Live4tvApiAuthRequest, loginUrl?: string}) {
    switch (action.type) {
        case types.ADD_LIVE4TV_TARGET_REQUEST:
            return Object.assign({}, state, { isLoading: false });
        case types.LIVE4TV_LOGIN_REQUEST:
            return Object.assign({}, state, { isLoading: true, errorMessage: null, infoMessage: null });
        case types.LIVE4TV_LOGIN_SUCCESS:
            return Object.assign({}, state, { isLoading: false, errorMessage: null, infoMessage: null });
        case types.LIVE4TV_LOGIN_FAIL:
            return Object.assign({}, state, { isLoading: false, errorMessage: action.errorMessage, infoMessage: null });
        case types.LIVE4TV_CHECKPOINT_REQUEST:
            if (action.payload === undefined || action.payload.streamTarget === undefined) {
                return state;
            }

            return Object.assign({}, state, { isLoading: false, errorMessage: null, infoMessage: action.infoMessage, streamTarget: action.payload.streamTarget });
        case types.LIVE4TV_RESET_INTEGRATION:
            return initialState;

        case types.LIVE4TV_UPDATE_LOGIN_URL:
            return Object.assign({}, state, { loginUrl: action.loginUrl });

        default:
            return state;
    }
}
