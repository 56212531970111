import { Theme } from "@mui/material";

const styles = (theme: Theme) => ({
    root: {
        backgroundColor: 'transparent',
        height: '100vh',
        width: '100vw',
        '& *::-webkit-scrollbar': {
            width: '8px',
        },
        '& *::-webkit-scrollbar-track': {
            background: 'transparent',
        },
        '& *::-webkit-scrollbar-thumb': {
            backgroundColor: 'transparent',
            borderRadius: '4px',
        },
    },
    chatContainer: {
        padding: '10px',
        width: '100%',
        height: '95vh',
        backgroundColor: 'transparent',
        overflowY: 'scroll' as 'scroll',
        // display: 'flex',
        // flexDirection: 'row' as 'row',
        gap: '8px',
    },

    message: {
        // backgroundColor: 'transparent',
        // backgroundColor: 'rgba(21, 21, 21, 0.08)',
        // marginTop: '10px',
        backdropFilter: 'blur(10px)',
        color: '#000000',
        padding: '8px 12px',
        borderRadius: '20px',
        display: 'flex',
        flexDirection: 'row' as 'row',
        alignItems: 'center',
        gap: '8px',
        width: '100%',
        boxSizing: 'border-box' as 'border-box',
    },
    profilePic: {
        width: '24px',
        height: '24px',
        minWidth: '24px',
        borderRadius: '50%',
        objectFit: 'cover' as 'cover',
        border: '2px solid rgba(255, 255, 255, 0.2)',
    },
    socialIcon: {
        fontSize: '14px',
        // color: 'rgba(255, 255, 255, 0.7)',
        display: 'flex',
        alignItems: 'center',
        minWidth: '14px',
    },
    iconButtons: {
        position: 'fixed' as 'fixed',
        top: '10px',
        right: '10px',
        zIndex: 1000,
        backgroundColor: 'rgba(21, 21, 21, 0.75)',
        backdropFilter: 'blur(10px)',
        borderRadius: '20px',
        padding: '4px',
        display: 'flex',
        gap: '4px',
        '& button': {
            color: 'rgba(255, 255, 255, 0.8)',
            '&:hover': {
                color: '#ffffff',
                backgroundColor: 'rgba(255, 255, 255, 0.1)',
            },
        },
    },
    username: {
        fontWeight: 600,
        color: '#000',
        opacity: 0.9,
        fontSize: '14px',
        whiteSpace: 'nowrap' as 'nowrap',
    },
    time: {
        color: '#000',
        fontWeight: 600,
        wordBreak: 'break-word' as 'break-word',
        fontSize: '13px',
    },
    messageText: {
        color: '#000',
        fontWeight: 600,
        wordBreak: 'break-word' as 'break-word',
        fontSize: '14px',
        flex: '1 1 auto',
    },
    '@keyframes slideIn': {
        from: {
            opacity: 0,
            transform: 'translateY(10px)',
        },
        to: {
            opacity: 1,
            transform: 'translateY(0)',
        },
    },
});

export default styles;
