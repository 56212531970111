export function imageExists(url: string): boolean {
    try {
        const http = new XMLHttpRequest();
        http.open('HEAD', url, false); // false makes it synchronous
        http.send();
        return ![403, 404].includes(http.status);

    } catch (e) {
        return false;
    }
}
