import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { useEffect } from 'react';

export const StreamTargetAccountSuccessPage = () => {
  const { t } = useTranslation();

  useEffect(() => {
    handleClose();
  }, []);

  const handleClose = () => {
    window.close();
  };

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh',
      padding: '20px',
      textAlign: 'center'
    }}>
      <h1>{t('streamTarget.accountSuccess.title')}</h1>
      <p>{t('streamTarget.accountSuccess.message')}</p>
      <Button
        variant="contained"
        color="primary"
        onClick={handleClose}
        sx={{ marginTop: '20px' }}
      >
        {t('streamTarget.accountSuccess.closeButton')}
      </Button>
    </div>
  );
};
