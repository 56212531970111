import { Alert, Button, FormControl, LinearProgress, Link, Paper, TextField, Typography } from "@mui/material";
import { withStyles } from "@mui/styles";
import React, { FormEvent, useEffect, useState } from "react";
import styles from "./LoginPage-jss";
import { Grid } from "@mui/material";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { live4tvUserService } from "./live4tvUserService";
import { connect } from "react-redux";
import { Auth, Profile } from "./userDTO";
import useCrashReport from "packages/useCrashReport/useCrashReport";
import { useNavigate } from "react-router-dom";

type Properties = {
    classes: any,
    auth: Auth,
    profile: Profile,
}

function MagicLinkLoginPage(props: Properties) {
    const {
        classes,
        auth,
        profile,
    } = props;

    const [isLoading, setIsLoading] = useState(false);
    const [formError, setFormError] = useState('');
    const [success, setSuccess] = useState('');

    const [email, setEmail] = useState('');

    const { t } = useTranslation();

    const crashReporter = useCrashReport();

    const navigate = useNavigate();

    useEffect(() => {
        console.debug('signedIn', auth.isLoggedIn);
        if (auth.isLoggedIn === true) {
            crashReporter.setUser(profile.code);
            navigate('/events');
        }
    }, [auth.isLoggedIn]);

    async function generateMagicLink(e: FormEvent<HTMLFormElement>) {
        e.preventDefault();

        setIsLoading(true);
        setFormError('');

        try {
            const response = await live4tvUserService.generateMagicLink(email);
            console.debug('generateMagicLink().response', response);
            setSuccess(t('Login link generated successfully. Please check your email for the link. It may take a few minutes to arrive.'));

        } catch (error: any) {
            console.debug('generateMagicLink().error', error);
            if (error.status !== undefined && error.status !== "400") {
                console.error('generateMagicLink().error', error);
            }

            setFormError(t('Invalid email or password'));
        }

        setIsLoading(false);
    }

    return (
        <Grid container className={classNames(classes.root)} direction="row" alignItems="center" justifyContent="center">
            <Grid item xs={10} md={8} lg={6} container direction="column">
                <Paper className={classNames(classes.paperForm)} elevation={0} square>
                    {success ? <Alert severity="success" className={classNames(classes.alert)}>{success}</Alert> : (
                        <form onSubmit={generateMagicLink} className={classNames(classes.form)}>
                            {isLoading ? <LinearProgress className={classNames(classes.progressBar)}/> : (
                                <>
                                    <Typography component="h1" variant="h5">
                                        {t("Login with your email")}
                                    </Typography>

                                    {formError ? <Alert severity="error" className={classNames(classes.alert)}>{formError}</Alert> : ''}

                                    <FormControl fullWidth className={classNames(classes.textField)}>
                                        <TextField
                                            autoFocus
                                            margin="dense"
                                            id="login"
                                            label={t("Enter with your Live4.tv email")}
                                            type="text"
                                            fullWidth
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                    </FormControl>
                                    <FormControl fullWidth className={classNames(classes.textField)}>
                                    </FormControl>

                                    <Button fullWidth size="large" type="submit" variant="contained" color="primary"
                                            className={classNames(classes.submit)}>{t("Generate Login link")}</Button>
                                </>
                            )}
                        </form>
                    )}

                </Paper>

                <Link href="#signup" color="textPrimary">
                    {t("You still have no account? Signup here!")}
                </Link>
            </Grid>
        </Grid>
    )
}

const mapStateToProps = function (state: any) {
    return {
        auth: state.auth,
        profile: state.user.profile
    }
}

export default withStyles(styles)(connect(mapStateToProps)(MagicLinkLoginPage));
