import React, {FormEvent, useEffect, useState} from "react";
import classNames from "classnames";
import {
    Alert,
    Button,
    FormControl,
    Grid,
    InputLabel,
    LinearProgress,
    Link, MenuItem,
    Paper, Select,
    TextField,
    Typography
} from "@mui/material";
import {withStyles} from "@mui/styles";
import styles from "./SignUpForm-jss";
import {live4tvUserService} from "./live4tvUserService";
import MuiPhoneNumber from "mui-phone-number";
import {useTranslation} from "react-i18next";

type Properties = {
    classes: any,
}
function MagicLinkSignupPage(props: Properties) {
    const {
        classes,
    } = props;

    const [isLoading, setIsLoading] = useState(false);
    const [formError, setFormError] = useState('');
    const [username, setUsername] = useState('');
    const [firstName, setFirstName] = useState('');
    const [persona, setPersona] = useState('');
    const [personaOther, setPersonaOther] = useState('');
    const [whatsapp, setWhatsapp] = useState('');
    const [success, setSuccess] = useState('');

    const [personaOptions, setPersonaOptions] = useState<string[]>([]);


    const { t } = useTranslation();

    useEffect(() => {
        console.debug('MagicLinkSignupPage');
    }, []);

    useEffect(() => {
        setPersonaOptions(
            [
                'Gamer',
                'Marketing',
                'Influencer',
                'Journalist',
                'Musician',
                'Politician',
                'Teacher',
                'Student',
                'Church',
                'Radio',
                'TV Station',
                'Shop',
                'Podcast',
                'Studio',
                'Public Sector',
                'Liveshop',
                'Sales',
                'Auction'
            ]
            .map((item: string) => t(item))
            .sort()
        );
    }, [t]);

    const handleSignUp = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            const breakDownName = firstName.split(' ');
            const fName = breakDownName[0];
            const lName = breakDownName.slice(1).join(' ');

            const response = await live4tvUserService.signUpMagicLink({
                firstName: fName,
                lastNames: lName,
                username: username,
                segment: 'na',
                persona: (persona === 'other') ? personaOther : persona,
                whatsapp: whatsapp,
            });
            console.debug('handleSignUp().signup', response);

            setSuccess(t('Login link generated successfully. Please check your email for the link. It may take a few minutes to arrive.'));

        } catch (e: any) {
            console.debug('handleLogin().error', e);
            let errors = '';
            if (e.errors !== undefined) {
                e.errors.forEach((error: string) => errors += t(error) + '\n');
            } else {
                errors = t('Something didn\'t work well. Please, try again');
            }
            setFormError(errors);
        }

        setIsLoading(false);
    }

    return (
        <Grid container className={classNames(classes.root)} direction="row" alignItems="center" justifyContent="center">
            <Grid item xs={10} md={8} lg={6} container direction="column" spacing={2}>
                <Grid item>
                    <Paper className={classNames(classes.paperForm)} elevation={0} square>
                        {success ? <Alert severity="success" className={classNames(classes.alert)}>{success}</Alert> : (
                            <form onSubmit={handleSignUp} className={classNames(classes.form)}>
                                {isLoading ? <LinearProgress className={classNames(classes.progressBar)}/> : (
                                    <div>
                                        <Typography component="h1" variant="h5">
                                            {t("Signup")}
                                        </Typography>
                                        {formError ? <Alert severity="error"
                                                            className={classNames(classes.alert)}>{formError}</Alert> : ''}

                                        <FormControl fullWidth className={classNames(classes.textField)}>
                                            <TextField
                                                fullWidth
                                                autoFocus
                                                margin="dense"
                                                id="firstname"
                                                label={t("Enter with your name")}
                                                type="text"
                                                value={firstName}
                                                onChange={(e) => setFirstName(e.target.value)}
                                                required
                                            />
                                        </FormControl>
                                        <FormControl fullWidth className={classNames(classes.textField)}>
                                            <TextField
                                                margin="dense"
                                                id="login"
                                                label={t("Enter with an email")}
                                                type="email"
                                                fullWidth
                                                value={username}
                                                onChange={(e) => setUsername(e.target.value)}
                                                required
                                            />
                                        </FormControl>

                                        <FormControl fullWidth></FormControl>

                                        <FormControl fullWidth className={classNames(classes.textField)}>
                                            <InputLabel>{t("Select your segment") + ' ' + t('(optional)')}</InputLabel>
                                            <Select
                                                fullWidth
                                                value={persona}
                                                onChange={(e) => setPersona(e.target.value as string)}>
                                                label={t("Select your segment") + ' ' + t('(optional)')}
                                                {personaOptions.map((item: string) => (
                                                    <MenuItem key={item} value={item}>{t(item)}</MenuItem>
                                                ))}
                                                <MenuItem value="other">{t("Other")}</MenuItem>
                                            </Select>
                                            {persona === 'other' && (
                                                <TextField
                                                    margin="dense"
                                                    id="persona"
                                                    label={t("Enter your segment") + ' ' + t('(optional)')}
                                                    type="text"
                                                    fullWidth
                                                    value={personaOther}
                                                    onChange={(e) => setPersonaOther(e.target.value)}
                                                />
                                            )}
                                        </FormControl>

                                        <FormControl fullWidth>
                                            <InputLabel>{t("What is your WhatsApp?") + ' ' + t('(optional)')}</InputLabel>
                                        </FormControl>
                                        <FormControl fullWidth></FormControl>
                                        <FormControl fullWidth>
                                            <MuiPhoneNumber inputClass={classes.whatsapp} id="whatsapp" value={whatsapp} defaultCountry={'br'} onChange={(value: any) => setWhatsapp(value as string)}/>
                                        </FormControl>

                                        <FormControl fullWidth></FormControl>
                                        <Button fullWidth size="large" type="submit" variant="contained" color="primary"
                                                className={classNames(classes.submit)}>{t("Signup")}</Button>
                                    </div>
                                )}

                                <div className={classNames(classes.serviceLinks)}>
                                    <Link href="#" onClick={(e) => {
                                        e.preventDefault();
                                        window.open('https://www.live4.tv/termos-de-servico', "_blank", "noreferrer");
                                    }} color="textPrimary">
                                        {t('Terms of Service')}
                                    </Link>

                                    <Link href="#" onClick={(e) => {
                                        e.preventDefault();
                                        window.open('https://www.live4.tv/politica-de-privacidade', "_blank", "noreferrer");
                                    }} color="textPrimary">
                                        {t("Privacy Policy")}
                                    </Link>
                                </div>
                            </form>
                        )}
                    </Paper>
                </Grid>

                <Grid item>
                    <Link href="#login" color="textPrimary">
                        {t("Do you already have an account? Click here to Login")}
                    </Link>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default withStyles(styles)(MagicLinkSignupPage);
